let analytics = false;

var scrollvalue = 0;
$(window).scroll(function () {
  if (($(window).scrollTop() - scrollvalue) > 400) {
    addAnalytics()
  }
});

function addAnalytics() {
  if (analytics === false) {
    analytics = true;
    loadScriptAsync('https://www.googletagmanager.com/gtag/js?id=UA-100426039-9', function () {
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        dataLayer.push(arguments);
      }

      gtag('js', new Date());
      gtag('config', 'UA-100426039-9', {'anonymize_ip': true});
    })
  }
}

/*This function will load script and call the callback once the script has loaded*/
function loadScriptAsync(scriptSrc, callback) {
  if (typeof callback !== 'function') {
    throw new Error('Not a valid callback for async script load');
  }
  var script = document.createElement('script');
  script.onload = callback;
  script.src = scriptSrc;
  document.head.appendChild(script);
}

document.addEventListener("DOMContentLoaded", function () {

  const es = require('../i18n/es.json');
  const en = require('../i18n/en.json');
  const de = require('../i18n/de.json');
  const fr = require('../i18n/fr.json');
  const it = require('../i18n/it.json');

  const menu = $("#landing-menu-open");

  const expWrapper = $(".disinformations-detail");
  const expTitle = $(".disinformations-detail h3");
  const expContent = $("#explanation-content");

  $(".menu-open").on("click", function () {
    menu.fadeIn(100);
    menu.css("display", "flex");
  })

  $(".menu-close").on("click", function () {
    menu.fadeOut(100);
  })

  $.i18n().load({
    es: es,
    en: en,
    de: de,
    fr: fr,
    it: it
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let urlLang = urlParams.get('lang');
  if (urlLang !== null) {
    console.dir(urlLang);
    $.i18n({
      locale: urlLang
    })
  }

  renderLanguage();

  $(".disinformation-topic").on('click', function () {
    let selectedTopic = $(this).children('p').data('i18n');

    let title = $.i18n(selectedTopic);
    let content = $.i18n(selectedTopic + '_exp');

    expTitle.text(title);
    expContent.html(content);
    expWrapper.slideDown();
  });

  $('#close-details').on('click', function () {
    expWrapper.slideUp();
  });

  $("#menu-options a").on('click', function () {
    menu.fadeOut(100);
  });

});

function renderLanguage() {

  let currentLanguage = $.i18n().locale;

  let cookiesWarning = $.i18n('cookies_warning');
  let readMore = $.i18n('read_more');

  window.cookieconsent.initialise({
    palette: {
      popup: {background: "#1aa3ff"},
      button: {background: "#e0e0e0"},
    },
    content: {
      message: cookiesWarning,
      dismiss: 'OK',
      allow: 'OK',
      deny: 'NO',
      link: readMore,
      href: 'https://www.cookiesandyou.com',
      close: '&#x274c;',
      policy: 'Cookie Policy',
      target: '_blank',
    },
    revokable: false,
    onInitialise: function () {
      if (this.hasConsented()) {
        addAnalytics();
      }
    },
    onStatusChange: function (status) {
      if (this.hasConsented()) {
        addAnalytics()
      }
    },
    "theme": "edgeless"
  });

  $('.download-button').each(function (x) {
    if (currentLanguage.includes($(this).data('lang'))) {
      $(this).css('display', 'inline-block');
    } else {
      $(this).css('display', 'none');
    }
  });

  $('*[data-lang]').each(function (x) {
    if (currentLanguage.includes($(this).data('lang'))) {
      $(this).css('font-weight', 'bolder');
    } else {
      $(this).css('font-weight', 'normal');
    }
  });

  $('.translatable').i18n();

  let subtitle = $.i18n('appname-subtitle');
  $('#landing-subtitle').html(subtitle)

  let intro1 = $.i18n('intro_1');
  $('#intro1').html(intro1)


  let garglingMobile = $.i18n('iframe-mobile-gargling');
  $('#gargling .mobile iframe').attr('src', garglingMobile);

  let takasuMobileIframe = $.i18n('iframe-mobile-takasu');
  $('#takasu .mobile iframe').attr('src', takasuMobileIframe);

  let fumigationMobile = $.i18n('iframe-mobile-fumigation');
  $('#fumigation .mobile').html(fumigationMobile);

  let mapMobile = $.i18n('iframe-mobile-map');
  $('#interactive-map .mobile iframe').attr('src', mapMobile);

  let billGatesMobile = $.i18n('iframe-mobile-billgates');
  $('#billgates .mobile iframe').attr('src', billGatesMobile);

  let mobileMobile = $.i18n('iframe-mobile-5g');
  $('#5g .mobile iframe').attr('src', mobileMobile);

  let billgates = $.i18n('iframe-billgates');
  $('#billgates .desktop iframe').attr('src', billgates);

  let radiation = $.i18n('iframe-5g');
  $('#5g .desktop iframe').attr('src', radiation);

  let takasuIframe = $.i18n('iframe-takasu');
  $('#takasu .desktop').html(takasuIframe);

  let countriesMap = $.i18n('iframe-countries');
  $('#interactive-map .desktop iframe').attr('src', countriesMap);

  let gargling = $.i18n('iframe-gargling');
  $('#gargling .desktop').html(gargling);

  let fumigation = $.i18n('iframe-fumigation');
  $('#fumigation .desktop').html(fumigation);

  let leyenda = $.i18n('leyenda');
  $('#leyenda').attr('src', leyenda);

  $(".graph-legend").each(function (x) {
    if (currentLanguage.includes($(this).data("language"))) {
      $(this).css("display", "block");
    } else {
      $(this).css("display", "none");
    }
  })

  const franceGraphIframe = $('.graphic-france');
  const germanyGraphIframe = $('.graphic-germany');
  const italyGraphIframe = $('.graphic-italy');
  const spainGraphIframe = $('.graphic-spain');
  const ukGraphIframe = $('.graphic-uk');

  let franceGraph = $.i18n('graphic-france');
  let germanyGraph = $.i18n('graphic-germany');
  let italyGraph = $.i18n('graphic-italy');
  let spainGraph = $.i18n('graphic-spain');
  let ukGraph = $.i18n('graphic-uk');

  franceGraphIframe.attr('src', franceGraph);
  germanyGraphIframe.attr('src', germanyGraph);
  italyGraphIframe.attr('src', italyGraph);
  spainGraphIframe.attr('src', spainGraph);
  ukGraphIframe.attr('src', ukGraph);
}
